.goodsNumber {
	color: #08070d;
	font-size: 14px;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.filtersContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
	min-width: 55vw;
}

.hiddenContainer {
	opacity: 0;
	transition: opacity 0.5s linear;
}

.selectedGoodsContainer__visible {
	opacity: 1;
	transition: opacity 0.5s linear;
	display: flex;
	align-items: center;
	gap: 10px;
}
.selectedGoodsContainer__hidden {
	opacity: 0;
	transition: opacity 0.5s linear;
	display: flex;
	align-items: center;
	gap: 10px;
}
.selectedGoodsContainer__weightContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	gap: 5px;
	background: #f2f2f2;
	padding: 8px;
}
.selectedGoodsContainer__weightContainer__label {
	font-size: 12px;
	color: #9d9dab;
	padding: 0;
	margin: 0;
}
@media only screen and (min-width: 1920px) {
	.selectedGoodsContainer__weightContainer__label {
		font-size: 14px;
	}
}
.selectedGoodsContainer__weightContainer__value {
	font-size: 14px;
	color: #08070d;
	font-weight: 700;
	padding: 0;
	margin: 0;
}
@media only screen and (min-width: 1920px) {
	.selectedGoodsContainer__weightContainer__value {
		font-size: 18px;
	}
}
