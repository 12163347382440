.profile {
	margin-left: auto;
	margin-right: auto;
}
.profile:hover {
	background-color: transparent;
}
.profile:focus {
	background-color: white;
	box-shadow: none;
}

.profileExtendedContainer {
	display: flex;
}

.businessUnit {
	font-size: 14px;
	line-height: 19px;
	color: #0b615b;
	height: 3vh;
	align-items: center;
	display: flex;
}

.businessUnitName {
	margin-left: 3px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 150px;
}
.businessUnitName:hover {
	overflow: visible;
	white-space: wrap;
}

.nameContainer {
	margin-left: 10px;
}

.name {
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #08070d;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 150px;
}
.name:hover {
	overflow: visible;
	white-space: wrap;
}
