.container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 16px;
	overflow-y: auto;
	overflow-x: hidden;
	transition-duration: 0.3s;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 16px;
}
.content__item {
	display: flex;
	flex-direction: row;
	gap: 24px;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid #f2f2f2;
}
.content__item__title {
	font-size: 14px;
	line-height: normal;
	color: #9d9dab;
}
.content__item__array {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	line-height: normal;
}
.content__item__value {
	font-size: 16px;
	line-height: normal;
	color: #08070d;
	text-align: justify;
}
.content :last-child {
	border-bottom: 0px;
}
