.card {
	width: 392px;
	height: 462px;
	padding: 32px;
}

.header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 12px;
	margin-bottom: 44px;
}
.header__title {
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	font-style: normal;
	color: #2e294e;
	margin: 0;
}
.header__description {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: #9d9dab;
	margin: 0;
}
.header__link {
	align-self: flex-end;
	text-align: right;
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	padding: 10px;
}

.mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
