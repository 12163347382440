.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	padding: 24px;
	border-radius: 16px;
	border: 1px solid #dadae0;
	transition: height 0.3s;
}

.header__title {
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	line-height: normal;
	color: #0b615b;
}

.content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px;
	width: 100%;
}

.loader {
	align-self: center;
}

.item__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
}
.item__header__title {
	color: #08070d;
	font-size: 16px;
	font-weight: 700;
}
.item__content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	font-size: 14px;
	font-weight: 400;
	padding: 8px 0px;
	min-height: 40px;
}
.item__content__label {
	color: #9d9dab;
	text-align: start;
}
.item__content__value {
	color: #08070d;
	text-align: justify;
}
.item__content__tags {
	display: flex;
	gap: 5px;
}
.item__content__break {
	border-bottom: 1px solid #f2f2f2;
	height: 1px;
}
