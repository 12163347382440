.container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 8px;
}
@media only screen and (min-width: 1920px) {
	.container {
		gap: 16px;
	}
}

.svgIcon > svg path {
	fill: #9d9dab;
}
