.container {
	display: flex;
	flex-direction: column;
}

.section {
	display: flex;
	flex-direction: column;
	border-top: 1px solid #f2f2f2;
	padding: 16px 0;
}

.content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
}
.content__title {
	font-size: 14px;
	line-height: normal;
	color: #9d9dab;
}
.content__array {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	line-height: normal;
}
.content__value {
	font-size: 16px;
	line-height: normal;
	color: #08070d;
	text-align: justify;
}
