.container {
	margin-bottom: 20px;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1.5vh;
}

.icon {
	align-self: center;
}
.icon__delete {
	cursor: pointer;
}

.confirm {
	cursor: pointer;
	padding: 4px;
}
.confirm__ok {
	color: red;
}
.confirm__cancel {
	color: black;
}
.confirm:hover {
	opacity: 0.8;
	border-radius: 4px;
	background-color: #f2f2f2;
}

.field {
	margin-top: 20px;
	margin-bottom: 20px;
}
