.container {
	margin-left: auto;
	margin-right: auto;
	height: 25vh;
	overflow-y: auto;
	width: 280px;
	margin-bottom: 20px;
}

.checkmark {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-top: 38px;
	margin-bottom: 28px;
	cursor: pointer;
	font-size: 14px;
	line-height: 16px;
	color: #08070d;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.checkmark > input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.checkmark > input:checked ~ .checkmark__radio:after {
	display: block;
}
.checkmark__radio {
	position: absolute;
	top: -5px;
	left: 0;
	height: 23px;
	width: 23px;
	background-color: #fff;
	border: 2px solid #0b615b;
	border-radius: 50%;
}
.checkmark__radio:after {
	top: 5px;
	left: 5px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #0b615b;
	content: "";
	position: absolute;
	display: none;
}
