.selectedCategory {
	background-color: #0b615b !important;
}
.selectedCategory svg path {
	stroke: white;
}

.categorySelection {
	display: flex;
	justify-content: space-between;
	width: 130px;
}
