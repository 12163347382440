.itemContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-inline: 5px;
	margin-top: 7px;
}

.itemName {
	padding: 0;
	margin: 0;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: #08070d;
}

.addButton {
	margin-top: 5px;
	display: flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
	color: #0b615b;
}

.plusIcon > svg path {
	stroke: #0b615b;
}

.negativeMargin {
	margin-bottom: -2px;
}

.input {
	min-height: 38px;
}

.deleteIcon {
	cursor: pointer;
}

.addButtonName {
	margin: 0;
	padding: 0;
}

.inputContainer {
	min-height: 80px;
}
