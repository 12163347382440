.header__title {
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	line-height: normal;
	color: #0b615b;
}

.loader {
	align-self: center;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	padding: 24px;
	border-radius: 16px;
	border: 1px solid #dadae0;
	transition: height 0.3s;
}

.content {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 48px;
	row-gap: 24px;
	margin: 8px 0;
}
.content__item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-style: normal;
	font-weight: 400;
	gap: 8px;
}
.content__item__title {
	font-size: 14px;
	line-height: normal;
	color: #9d9dab;
}
.content__item__wrapper {
	display: flex;
	flex-direction: column;
}
.content__item__value {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.5px;
	color: #08070d;
}
@media only screen and (min-width: 1920px) {
	.content {
		grid-template-columns: repeat(6, 1fr);
	}
}
