.drawerItem {
	display: flex;
	justify-content: center;
	margin: 8px 16px;
	padding: 8px;
	border-radius: 8px;
}
.drawerItem:hover {
	background-color: white;
}
.drawerItem:focus {
	box-shadow: none;
}
@media only screen and (min-width: 1920px) {
	.drawerItem {
		padding: 12px;
	}
}

.expanded {
	margin: 8px 1vw 0 1vw;
	border-radius: 8px;
}
.expanded.drawerItem {
	justify-content: left;
}

.svgIconStyle > svg {
	fill: none;
}

.mainItem {
	font-weight: 700;
}
