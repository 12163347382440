.container {
	display: flex;
	justify-content: center;
}

.gray {
	background-color: #9d9dab;
}

.yellow {
	background-color: #e5c100;
}

.orange {
	background-color: #e59500;
}

.blue {
	background-color: #0563c1;
}

.violet {
	background-color: #2e294e;
}

.pink {
	background-color: #e50683;
}

.green {
	background-color: #0ba008;
}

.darkGreen {
	background-color: #0b615b;
}

.lightGray {
	background-color: #a5c4d4;
}

.red {
	background-color: #e50606;
}

.purple {
	background-color: #7b439e;
}
