.container {
	width: 230px;
}

.header {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 4px 8px;
}

.title {
	padding: 0;
	margin: 0;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

.checkboxContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
	padding: 0 8px;
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
}

.checkboxElement {
	padding: 8px 0;
}

.buttonsContainer {
	margin-top: 16px;
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}
