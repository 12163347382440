.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #08070d;
	padding: 0;
	margin: 0;
}

.actions {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-left: 24px;
}
