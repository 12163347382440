.status__anchor {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 12px;
	padding: 4px 8px;
	border-radius: 20px;
	font-weight: 700;
	line-height: 14px;
	color: #ffffff;
	transition-duration: 0.3s;
	justify-content: center;
}
.status__anchor__interactive {
	cursor: pointer;
}
.status__popup {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
}
.status__popup__item {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px;
	cursor: pointer;
	background-color: white;
	transition-duration: 0.3s;
}
.status__popup__item__dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.status__popup__item__label {
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	color: #6d6d7d;
}
.status__popup__item:hover {
	opacity: 0.8;
	background-color: #f2f2f2;
}
.status__gray {
	background-color: #9d9dab;
}
.status__yellow {
	background-color: #e5c100;
}
.status__orange {
	background-color: #e59500;
}
.status__blue {
	background-color: #0563c1;
}
.status__violet {
	background-color: #2e294e;
}
.status__pink {
	background-color: #e50683;
}
.status__green {
	background-color: #0ba008;
}
.status__darkGreen {
	background-color: #0b615b;
}
.status__lightGray {
	background-color: #a5c4d4;
}
.status__red {
	background-color: #e50606;
}
.status__purple {
	background-color: #7b439e;
}
.status__chevronDown {
	transform: rotate(0);
	transition-duration: 0.3s;
}
.status__chevronUp {
	transform: rotate(180deg);
	transition-duration: 0.3s;
}
