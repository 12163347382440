* {
	margin: 0;
	padding: 0;
	box-sizing: border-box; /* I do this for all my projects */
}

/* You can add global styles to this file, and also import other style files */
html,
body {
	font-family: "Roboto", sans-serif;
	margin: 0 auto;
	overflow: auto;
	--kendo-scrollbar-width: 8px !important;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background-color: transparent; /* Change track color */
	border-radius: 8px; /* Adjust border radius */
}

::-webkit-scrollbar-thumb {
	width: 8px;
	background-color: #0b615b;
	border-radius: 8px;
}

.popupMenu.k-menu > .k-item,
.popupMenu.k-menu > .k-item.k-hover {
	color: black;
}
.popupMenu.k-menu > .k-item:hover,
.popupMenu.k-menu > .k-item.k-hover:hover {
	opacity: 0.7;
	color: black;
}
.popupMenu.k-menu .separator {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.popupMenu.k-menu .red {
	color: #e50606;
}
.popupMenu.k-menu .red:hover {
	color: #e50606;
	opacity: 0.7;
}

.k-grid {
	border-width: 0px;
	border-style: none;
}
.k-grid table tr:hover td {
	background: rgba(11, 97, 91, 0.2);
	color: black;
}
.k-grid .k-grid-header {
	border: 8px;
	background-color: #ffffff;
}
.k-grid .k-grid-header .k-grid-header-wrap {
	border-color: #ffffff;
	border-inline-end-width: 1px;
}
.k-grid .k-grid-header .k-table-th {
	border-top: 1px solid #9d9dab;
	border-right: 2px solid #9d9dab;
	text-align: center;
	white-space: break-spaces;
	vertical-align: middle;
}
.k-grid .k-grid-header .k-table-th .k-header.k-sorted {
	background-color: #dadae0;
}
.k-grid .k-grid-header .k-table-th:first-child {
	border-left: 1px solid #9d9dab;
	border-top-left-radius: 8px;
	text-align: left;
}
.k-grid .k-grid-header .k-table-th:last-child {
	border-top-right-radius: 8px;
	border-right: 1px solid #9d9dab;
}
.k-grid .k-table-row td,
.k-grid .k-table-row .k-table-td {
	border-inline-start-width: 0px;
	border-right: 2px solid #9d9dab;
	text-align: center;
}
.k-grid .k-table-row td:first-child,
.k-grid .k-table-row .k-table-td:first-child {
	border-left: 1px solid #9d9dab;
	text-align: left;
}
.k-grid .k-table-row td:last-child,
.k-grid .k-table-row .k-table-td:last-child {
	border-right: 1px solid #9d9dab;
}
.k-grid .k-table-row:last-child td,
.k-grid .k-table-row:last-child .k-table-td {
	border-bottom: 1px solid #9d9dab;
}
.k-grid .k-table-row:last-child td:first-child,
.k-grid .k-table-row:last-child .k-table-td:first-child {
	border-bottom-left-radius: 8px;
}
.k-grid .k-table-row:last-child td:last-child,
.k-grid .k-table-row:last-child .k-table-td:last-child {
	border-bottom-right-radius: 8px;
}
.k-grid .k-table-row.k-table-alt-row {
	background-color: #f2f2f2;
}
.k-grid .k-grid-content {
	/* For Firefox */
	scrollbar-color: #0b615b transparent; /* thumb color and track color */
	scrollbar-width: 8px; /* Adjust width (thin or auto) */
	/* For Chrome, Edge, and Safari */
}
.k-grid .k-grid-content::-webkit-scrollbar {
	background-color: transparent;
	width: 8px;
}
.k-grid .k-grid-content::-webkit-scrollbar-track {
	background-color: transparent; /* Change track color */
	border-radius: 8px; /* Adjust border radius */
}
.k-grid .k-grid-content::-webkit-scrollbar-thumb {
	background-color: #0b615b; /* Change thumb color */
	border-radius: 8px; /* Adjust border radius */
}

.k-picker {
	background-color: white;
	border: 1px solid #9d9dab;
}

.k-picker-solid {
	background-image: none;
}

.k-item-text {
	padding-right: 5px;
}

.k-drawer-item {
	justify-content: center;
	margin: 4px;
}

.k-drawer {
	margin: 16px;
	border-radius: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.08);
}
.k-drawer .k-drawer-wrapper {
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	transition: all 300ms ease-in-out;
	width: 100% !important;
}
.k-drawer .k-drawer-wrapper ul,
.k-drawer .k-drawer-wrapper .k-drawer-items {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
}
.k-drawer .k-drawer-wrapper ul[aria-expanded="false"],
.k-drawer .k-drawer-wrapper .k-drawer-items[aria-expanded="false"] {
	width: 100px;
}
.k-drawer .k-drawer-wrapper ul li:last-of-type,
.k-drawer .k-drawer-wrapper .k-drawer-items li:last-of-type {
	margin-top: auto;
	margin-bottom: 8px;
}
.k-drawer .k-drawer-wrapper::-webkit-scrollbar {
	width: 8px;
}
.k-drawer .k-drawer-wrapper::-webkit-scrollbar-thumb {
	background-color: #0b615b;
	border-radius: 8px;
}
.k-drawer .k-drawer-wrapper::-webkit-scrollbar-track {
	background-color: transparent;
}

.k-drawer-content {
	/** Negative margin-right value to compensate for grid scroll bars. */
	margin: 16px -8px 16px 0;
}

.k-window {
	max-height: 100%;
	border-radius: 8px;
	transition-duration: 0.3s;
	transition-property: width;
}

.k-input-solid.k-invalid {
	border: 1px solid #9d9dab;
}

.k-picker-solid.k-invalid {
	border: 1px solid #9d9dab;
}

.k-input-solid {
	border: 1px solid #9d9dab;
}

.k-stepper .k-step-current .k-step-indicator {
	border-color: #0b615b;
	background-color: #0b615b;
	color: white;
}

.k-stepper .k-step-done .k-step-indicator {
	border-color: #0b615b;
	background-color: #0b615b;
	color: white;
}

.k-progressbar .k-selected {
	border-color: #0b615b;
	background-color: #0b615b;
	color: white;
}

.k-notification {
	border-radius: 4px;
	padding: 14px 16px;
}
.k-notification .k-notification-content {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.k-notification-success {
	background-color: #f4fff3;
	border: 1px solid #0ba008;
}

.k-notification-error {
	background: #f9eded;
	border: 1px solid #e60606;
}

.k-notification-warning {
	background: #fdf5e7;
	border: 1px solid #e59500;
}

.k-notification-info {
	background: #f0f8ff;
	border: 1px solid #0563c1;
}

.k-master-row {
	background-color: white;
}

.k-radio:checked,
.k-radio.k-checked {
	border-color: white;
	color: black;
	background-color: black;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.k-expander {
	border: 1px solid #9d9dab;
	border-radius: 8px;
	margin-bottom: 5px;
	width: 100%;
	background-color: white;
}

.k-expander-header:hover {
	background-color: white;
}

.k-expander.k-focus {
	border: 1px solid #0b615b;
	box-shadow: none;
}

.k-expander-title {
	text-transform: none;
	color: #08070d;
	width: 100%;
}

.k-upload .k-dropzone,
.k-upload .k-upload-dropzone {
	background-color: white;
	border: 1px solid #9d9dab;
	border-radius: 4px;
}

.k-upload {
	border: none;
}
.k-upload .k-upload-files {
	max-height: 100px;
}
@media only screen and (min-width: 1920px) {
	.k-upload .k-upload-files {
		max-height: 200px;
	}
}
.k-upload .k-upload-files .k-file-name {
	max-width: 150px;
	max-height: unset;
	overflow: unset;
	text-overflow: unset;
	white-space: unset;
}

.k-upload .k-button.k-button-solid-base.k-button-md.k-input-button {
	background-color: transparent;
}

.k-upload-button {
	background-color: white;
}

.k-list-item.k-selected,
.k-drawer-item.k-selected {
	background-color: #0b615b;
	border-radius: 8px;
	color: var(--kendo-color-on-primary, #ffffff);
	font-weight: 700;
}
.k-list-item.k-selected svg path,
.k-drawer-item.k-selected svg path {
	stroke: white;
}
.k-list-item.k-selected:hover,
.k-drawer-item.k-selected:hover {
	background-color: #0b615b;
}

.k-calendar-td.k-state-pending-focus.k-selected.k-focus.k-link {
	background-color: #0b615b;
}

.k-today {
	color: green;
}

.k-dropzone.k-upload-dropzone {
	display: flex;
	flex-direction: row-reverse;
	height: 38px;
	margin-bottom: 34px;
}

.k-dropzone-hint {
	text-align: start;
}

.k-calendar {
	border-width: 0;
}
.k-calendar .k-calendar-td.k-selected .k-link {
	background-color: #0b615b;
	transition-duration: 0.3s;
}
.k-calendar .k-calendar-td.k-selected .k-link:hover {
	background-color: #0b615b;
	opacity: 0.8;
}
.k-calendar .k-calendar-view .k-today {
	color: #0b615b;
}
.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
	background-color: rgba(11, 97, 91, 0.3);
}

.k-time-list .k-item:hover {
	color: #0b615b;
}

.k-button-solid-primary {
	background-color: #0b615b;
	transition-duration: 0.3s;
	border: none;
}
.k-button-solid-primary:hover {
	background-color: #0b615b;
	opacity: 0.8;
}

.k-button-solid-base.k-selected {
	border: none;
	background-color: #0b615b;
	transition-duration: 0.3s;
}
.k-button-solid-base.k-selected:hover {
	background-color: #0b615b;
	opacity: 0.8;
}

.k-time-header .k-time-now {
	color: #0b615b;
}
.k-time-header .k-time-now:hover {
	color: #0b615b;
}

.k-chip-solid-base {
	background: #f2f2f2;
	border-radius: 2px;
	color: #9d9dab;
	border-color: transparent;
	box-shadow: 1px 2px rgba(127, 127, 127, 0.15);
}

.k-input-md {
	border: 1px solid #9d9dab;
}

.k-file-name {
	max-width: 300px;
}

.k-textarea {
	max-width: 450px;
	max-height: 200px;
}

.k-checkbox-lg {
	width: 16px;
	height: 16px;
}

.k-checkbox {
	border-color: rgba(0, 0, 0, 0.5411764706);
	border-width: 2px;
}
.k-checkbox + .k-checkbox-label {
	font-size: 14px;
	line-height: 20px;
	color: #08070d;
	margin-inline-start: 16px;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
	border-color: #0b615b;
	color: white;
	background-color: #0b615b;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
	box-shadow: none;
}

.k-dialog-wrapper {
	/*
	 * This is needed after the last update from KendoReact, where the Kendo React Team have considered to set z-index of the modals to
	 * 10002, which affects usage of the datepicker in modals.
	 */
	z-index: 99 !important;
}

input[type="checkbox"] {
	accent-color: #0b615b;
}

.autocomplete {
	min-height: 38px;
}

.combobox.k-input-md .k-input-button {
	display: none;
}

.k-chip-solid-base {
	max-width: 150px;
	text-overflow: ellipsis;
}

#nprogress .bar {
	background: #0b615b;
}

#nprogress .peg {
	box-shadow: 0 0 10px #0b615b, 0 0 5px #0b615b;
}

#nprogress .spinner {
	display: none;
}

.k-expander-title .k-animation-container {
	z-index: 101 !important;
}

.k-grid .k-grid-content {
	scrollbar-color: inherit;
}

.k-input-inner {
	z-index: inherit;
}
