.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 4px;
	align-items: center;
}
.grid__placeholder {
	color: #08070d;
	font-weight: 700;
	font-size: 10px;
	line-height: 21px;
}
@media only screen and (min-width: 1920px) {
	.grid__placeholder {
		font-size: 14px;
	}
}
.grid__item {
	display: inline-block;
	margin: auto;
	text-align: center;
	padding: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-radius: 2px;
	cursor: pointer;
	font-size: 10px;
	height: 24px;
	width: 70px;
}
.grid__item__value {
	background: #dadae0;
	color: #0563c1;
}
.grid__item__all {
	background: white;
	color: #0563c1;
	cursor: pointer;
}
@media only screen and (min-width: 1920px) {
	.grid__item {
		font-size: 12px;
		padding: 2px;
		width: 80px;
	}
}
@media only screen and (min-width: 2560px) {
	.grid__item {
		font-size: 14px;
		padding: 4px;
		width: 100px;
	}
}
@media only screen and (min-width: 1920px) {
	.grid {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 4px 3px;
		align-items: center;
	}
}

.modal {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
}
.modal__item {
	background: #dadae0;
	border-radius: 2px;
	padding: 4px;
	color: #0563c1;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}
