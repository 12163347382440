.dangerIconContainer {
	text-align: center;
}

.itemName {
	color: #e50606;
}

.subtitle {
	color: #6d6d7d;
	margin-top: 1vh;
	margin-bottom: 4vh;
	text-align: center;
}

.title {
	text-align: center;
}

.buttonsContainer {
	display: flex;
	justify-content: center;
	gap: 15px;
}

.modalContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
