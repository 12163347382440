.container {
	display: flex;
	align-items: flex-start;
}

.icon {
	align-self: center;
}

.info {
	margin-left: 1.5vw;
	font-size: 0.66rem;
	font-weight: 400;
	max-width: 200px;
}
.info__name {
	color: #08070d;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
}
.info__download {
	color: #0563c1;
	margin: 0;
	cursor: pointer;
}
