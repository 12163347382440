.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 44px;
	gap: 12px;
}

.element {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.buttonContainer {
	display: flex;
	flex-grow: 1;
	gap: 12px;
}
