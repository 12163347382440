.container {
	display: flex;
	flex-direction: column;
}

.section {
	border-top: 1px solid #f2f2f2;
	padding: 16px 0;
}

.wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin: 8px 0;
}
@media only screen and (min-width: 1920px) {
	.wrapper {
		gap: 20px;
	}
}

.information {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
}
.information__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}
.information__title__label {
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #9d9dab;
}
.information__value {
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #08070d;
}

.homeBusinessUnit {
	display: flex;
	align-items: center;
	gap: 4px;
	line-height: 19px;
}

.goods {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 16px;
	row-gap: 24px;
	margin: 8px 0;
}
@media only screen and (min-width: 1920px) {
	.goods {
		grid-template-columns: repeat(10, 1fr);
	}
}

.notes__label {
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	color: #08070d;
}
.notes__value {
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	color: #08070d;
	text-align: justify;
}
