.container {
	width: 480px;
}

.header {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 4px 8px;
}

.title {
	padding: 0;
	margin: 0;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	transition-duration: 0.2s;
}

.buttonsContainer {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
	gap: 8px;
}
