.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 38px;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	margin-bottom: 20px;
	margin-right: 8px;
	padding: 12px 16px;
}

.wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}

.title {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	color: #08070d;
}

.total {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 43px;
	height: 18px;
	background: #dadae0;
	border-radius: 12px;
}
.total > p {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #9d9dab;
}
