.root {
	width: 100%;
}

.input {
	height: 38px;
	padding: 9px 0px 9px 16px;
	border: 1px solid #2e294e;
	border-radius: 4px;
}
.input:hover {
	border: 2px solid #0b615b;
}
.input:focus-within {
	border: 2px solid #0b615b;
	box-shadow: none;
}

.passwordInputContainer {
	position: relative;
}

.eyeIcon {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
	cursor: pointer;
}
.eyeIcon__enable {
	color: black;
}
.eyeIcon__disabled {
	color: grey;
}

.search {
	height: 38px;
	max-width: 250px;
	margin-left: 32px;
}
.search__iconContainer {
	margin-left: 10px;
}
