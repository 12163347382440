.addLocation {
	display: flex;
	align-items: center;
	color: #0b615b;
	font-size: 14px;
	font-weight: 700;
	padding: 8px;
	cursor: pointer;
	gap: 6px;
}
.addLocation__label {
	text-decoration: underline;
}
