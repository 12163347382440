.link {
	text-decoration: none;
	color: #0563c1;
	background: #dadae0;
	cursor: pointer;
	font-size: 14px;
	padding: 0px 8px;
	border-radius: 2px;
	width: max-content;
}
