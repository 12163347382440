.input {
	min-height: 38px;
}

.multiselect {
	max-height: 70px;
	overflow: auto;
}

.textarea {
	min-height: 132px;
}

.inputContainer {
	margin-bottom: 5px;
	height: 80px;
}

.textAreaContainer {
	min-height: 174px;
	margin-bottom: 5px;
}
