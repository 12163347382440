.formContainer {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.formMain {
	flex-grow: 15;
}

.formIcon {
	flex-grow: 1;
}

.arrowIconStyle {
	margin-top: 3em;
}
