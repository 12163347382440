.container {
	display: flex;
	justify-content: center;
	margin-bottom: 1vh;
	max-height: 50vh;
	overflow-y: auto;
}

.table {
	border-collapse: collapse;
	width: 90%;
	table-layout: fixed;
}
.table__row {
	border-bottom: 1pt solid rgba(0, 0, 0, 0.12);
	height: 2vh;
}
.table__headerCell {
	text-align: left;
	color: #6d6d7d;
	padding-bottom: 5px;
	font-size: 12px;
	font-weight: 700;
}
.table__dataCell {
	color: #08070d;
	font-weight: 400;
	font-size: 14px;
}
.table__thead {
	position: sticky;
	top: 0;
	background: white;
	border-bottom: 1pt solid rgba(0, 0, 0, 0.12);
	height: 2vh;
}
