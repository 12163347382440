.formColumns {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1vw;
}

.formSecondColumn {
	border-right: 1px solid #9d9dab;
	padding-right: 1rem;
}
