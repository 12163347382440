.card {
	width: 392px;
	padding: 32px;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 12px;
	margin-bottom: 44px;
}

.title {
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	font-style: normal;
	color: #2e294e;
	margin: 0;
}

.description {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: #9d9dab;
	margin: 0;
}

.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 44px;
	gap: 12px;
	overflow-y: auto;
}
