.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	margin-bottom: 16px;
	height: 50px;
	padding: 0px 12px;
}

.wrapper {
	display: flex;
	align-items: center;
}
.wrapper__header {
	display: flex;
	flex-direction: column;
	color: #08070d;
	font-weight: 700;
	margin-left: 1vw;
}
.wrapper__header__title {
	font-size: 14px;
}
@media only screen and (min-width: 1920px) {
	.wrapper__header__title {
		font-size: 18px;
	}
}
.wrapper__header__subtitle {
	font-size: 8px;
}
@media only screen and (min-width: 1920px) {
	.wrapper__header__subtitle {
		font-size: 10px;
	}
}
.wrapper__back {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 4px;
	border-radius: 20px;
	transition-duration: 0.3s;
}
.wrapper__back:hover {
	opacity: 0.8;
	background-color: #f2f2f2;
}
.wrapper__status {
	margin-left: 1vw;
}

.gray {
	background-color: #9d9dab;
}

.yellow {
	background-color: #e5c100;
}

.orange {
	background-color: #e59500;
}

.blue {
	background-color: #0563c1;
}

.violet {
	background-color: #2e294e;
}

.pink {
	background-color: #e50683;
}

.green {
	background-color: #0ba008;
}

.darkGreen {
	background-color: #0b615b;
}

.lightGray {
	background-color: #a5c4d4;
}

.red {
	background-color: #e50606;
}

.purple {
	background-color: #7b439e;
}
