.logoHover {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	height: 10vh;
}

.logo {
	display: flex;
	flex-direction: row;
	align-items: center;
}
