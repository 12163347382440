.container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 8px;
	padding: 5px;
}

.tag {
	max-width: 3.5vw;
	background: #dadae0;
}
