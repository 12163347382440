.container {
	display: inline-block;
	background: #f2f2f2;
	border-radius: 2px;
	text-align: center;
	padding: 3px 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 7.5vw;
}

.title {
	font-size: 12px;
	line-height: 14px;
	color: #2e294e;
}
