.container {
	margin-top: 10px;
	box-shadow:
		0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px rgba(0, 0, 0, 0.14),
		0px 1px 10px rgba(0, 0, 0, 0.12);
	background-color: #ffffff;
	padding: 8px;
	width: 230px;
}
