.container {
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 1.5px 8px;
	border-radius: 20px;
	color: #ffffff;
	font-size: 12px;
	transition-duration: 0.3s;
}
