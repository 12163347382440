.form {
	display: grid;
	grid-gap: 1vw;
}
.form__two {
	grid-template-columns: 1fr 1fr;
}
.form__three {
	grid-template-columns: 1fr 1fr 1fr;
}

.chaufferContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-bottom: 24px;
	height: 80px;
}

.checkmark {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-top: 38px;
	margin-bottom: 28px;
	cursor: pointer;
	font-size: 14px;
	line-height: 16px;
	color: #08070d;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.checkmark > input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.checkmark > input:checked ~ .checkmark__radio:after {
	display: block;
}
.checkmark__radio {
	position: absolute;
	top: -5px;
	left: 0;
	height: 23px;
	width: 23px;
	background-color: #fff;
	border: 2px solid #0b615b;
	border-radius: 50%;
}
.checkmark__radio:after {
	top: 5px;
	left: 5px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #0b615b;
	content: "";
	position: absolute;
	display: none;
}
.checkmark:hover input ~ .checkmark__radio {
	background-color: #f2f2f2;
}

.currency {
	display: flex;
	justify-content: space-between;
	gap: 8px;
}
.currency__cost {
	width: 75%;
	height: 80px;
	margin-bottom: 14px;
}
.currency__code {
	width: 25%;
	height: 80px;
	margin-bottom: 14px;
}
