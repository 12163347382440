.container {
	margin-top: 1vh;
	overflow-y: auto;
	transition-duration: 0.3s;
}

.scrollPadding {
	padding-right: 8px;
}

.accordion {
	width: 100%;
}
