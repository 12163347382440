.element {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.inputs {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 44px;
	gap: 12px;
}
.inputs__link {
	align-self: flex-end;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: #0b615b;
}
