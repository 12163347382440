.container {
	display: flex;
	align-items: center;
	cursor: pointer;
	line-height: normal;
	gap: 8px;
}

.normal {
	color: #6d6d7d;
	font-size: 10px;
	font-weight: 400;
	font-style: normal;
	transition-duration: 0.3s;
}
@media only screen and (min-width: 1920px) {
	.normal {
		font-size: 16px;
	}
}

.filtered {
	color: #0b615b;
	font-size: 12px;
	font-weight: 700;
	font-style: bold;
	transition-duration: 0.3s;
}
@media only screen and (min-width: 1920px) {
	.filtered {
		font-size: 18px;
	}
}

.chevron__normal > svg path {
	stroke: #6d6d7d;
	transition-duration: 0.3s;
}

.chevron__filtered > svg path {
	stroke: #0b615b;
	transition-duration: 0.3s;
}

.chevron__down {
	transform: rotate(0deg);
	transition-duration: 0.3s;
}

.chevron__up {
	transform: rotate(180deg);
	transition-duration: 0.3s;
}

.count {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}
.count__title {
	color: white;
	font-size: 10px;
	font-weight: 700;
	font-style: bold;
}
@media only screen and (min-width: 1920px) {
	.count__title {
		font-size: 14px;
	}
}
.count__normal {
	width: 20px;
	height: 20px;
	transition-duration: 0.3s;
	background-color: #6d6d7d;
}
.count__filtered {
	width: 24px;
	height: 24px;
	transition-duration: 0.3s;
	background-color: #0b615b;
}
