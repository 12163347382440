.root {
	display: flex;
	height: 100vh;
}

.leftSide {
	width: 50%;
	height: 100%;
	background-image: url("../../../assets/images/UKTruck.png");
	background-size: cover;
	background-position: center;
}

.rightSide {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
