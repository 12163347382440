.gridHeader {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	justify-content: center;
	color: #08070d;
	background-color: #dadae0;
}
.gridHeader:first-child {
	justify-content: start;
}

.gridActionsHeader {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	justify-content: center;
	color: #08070d;
	background-color: #dadae0;
}

.gridContainer {
	max-height: calc(100vh - 116px);
}

.gridContainerEmpty {
	display: block;
}

.columnContainer {
	color: #08070d;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
}
