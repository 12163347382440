.containerOpen {
	width: calc(100% - 8px);
	transition: width 0.1s;
}

.containerClose {
	width: calc(100% - 8px);
	transition: width 0.1s;
}

.buttonContainer {
	display: flex;
	gap: 10px;
}

.informationContainer {
	grid-area: a;
	height: calc(100vh - 98px);
	padding: 24px;
}

.checklistContainer {
	grid-area: b;
	height: calc(100vh - 98px);
	padding: 24px;
}

.cardsContainer {
	display: grid;
	grid-template-columns: 80% 20%;
	grid-template-rows: 50% 50%;
	grid-template-areas: "a b";
	gap: 16px;
	height: calc(100vh - 116px);
	margin-right: 16px;
}
