.container {
	display: table-cell;
	vertical-align: middle;
	align-items: center;
}

.commandCellContainer {
	display: flex;
	justify-content: center;
	gap: 8px;
	align-items: center;
	padding-top: auto;
}
