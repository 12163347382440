.root {
	display: flex;
	height: 100vh;
}

.leftSide {
	width: 50%;
	height: 100%;
	background-image: url("../../../assets/images/UKTruck.png");
	background-size: cover;
	background-position: center;
	animation: slideImage 3s ease forwards;
}

.rightSide {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes slideImage {
	from {
		transform: translateX(-50%);
	}
	to {
		transform: translateX(0);
	}
}
