.container {
	margin-top: 16px;
	overflow-y: auto;
	overflow-x: hidden;
	transition-duration: 0.3s;
}

.scrollPadding {
	padding-right: 12px;
}

.noScroll {
	padding-right: 4px;
}

.paragraphDescriptionContainer {
	overflow-y: auto;
}

.paragraphDescription {
	font-size: 0.66rem;
}

.secondRowContainer {
	display: flex;
}

.documentsContainer {
	overflow-y: auto;
	padding-right: 2vw;
	height: 7vw;
	width: 40vw;
}

.addressCardContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	margin-bottom: 10px;
}
