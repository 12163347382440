.profileContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6d6d7d;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	border: 1px solid #dadae0;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}
