.formColumns {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1vw;
}

.map {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 400px;
	margin: 32px 0;
}
.map__title {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.map__loader {
	align-self: center;
}

.subtitle {
	font-size: 12px;
	color: black;
}

.buttonsContainer {
	display: flex;
	justify-content: right;
	gap: 5px;
}
