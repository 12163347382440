.containerOpen {
	width: calc(100% - 8px);
	transition: width 0.1s;
}

.containerClose {
	width: calc(100% - 8px);
	transition: width 0.1s;
}

.driverContainer {
	grid-area: a;
	height: calc(100vh - 98px);
	padding: 8px 16px 8px 16px;
}

.qualificationsContainer {
	grid-area: b;
	height: calc(100vh - 98px);
	padding: 8px 16px 8px 16px;
}

.cardsContainer {
	display: grid;
	grid-template-columns: 55% 45%;
	grid-template-rows: 50% 50%;
	grid-template-areas: "a b";
	gap: 16px;
	height: calc(100vh - 116px);
	margin-right: 16px;
}
