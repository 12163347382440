.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 18px;
}
.header__title {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	color: #08070d;
}
.header__icon {
	cursor: pointer;
}
