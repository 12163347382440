.viewDetailsButtonContainer {
	margin-right: 1vw;
}

.verticalWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.messageWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
