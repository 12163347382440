.header {
	margin-bottom: 24px;
}
.header__description {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #08070d;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	overflow-x: hidden;
	overflow-y: auto;
	transition-duration: 0.3s;
}

.scrollPadding {
	padding-right: 8px;
}
