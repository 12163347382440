.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 90vw;
	flex-direction: column;
}

.title {
	font-size: 32px;
}

.subtitle {
	width: 60vw;
	text-align: center;
	font-size: 22px;
	line-height: 24px;
	margin-top: 16px;
}
