.cardsContainer {
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: 50% 50%;
	grid-template-areas: "a b" "c b";
	gap: 16px;
	height: calc(100vh - 116px);
	margin-right: 16px;
}
.cardsContainer__open {
	grid-template-columns: 45% 55%;
}
.cardsContainer__close {
	grid-template-columns: 40% 60%;
}

.containerOpen {
	width: calc(100% - 8px);
	transition: width 0.1s;
}

.containerClose {
	width: calc(100% - 8px);
	transition: width 0.1s;
}

.jobInfoContainer,
.goodsContainer,
.legsContainer {
	padding: 8px 16px 8px 16px;
}

.jobInfoContainer {
	grid-area: a;
}

.goodsContainer {
	grid-area: c;
}

.legsContainer {
	grid-area: b;
}

.verticalWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 523px;
}
