.header {
	margin-bottom: 10px;
}
.header__title {
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	color: #08070d;
}

.container {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	gap: 10px;
}

.loader {
	align-self: center;
}
