.icon {
	display: flex;
	justify-content: center;
	align-content: center;
	height: 100%;
	align-items: center;
	width: 100%;
	color: #9d9dab;
}

.iconContainer {
	height: 100%;
	background-color: #f2f2f2;
	width: 30px;
}
