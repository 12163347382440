.root {
	position: fixed;
	width: 100%;
	height: 100%;
	font-style: normal;
	font-size: 30px;
	line-height: 19px;
	z-index: 99;
}

.logoPic {
	background: url("../../../../../../../assets/images/TLSLogo.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	height: 50px;
	width: 75px;
}
