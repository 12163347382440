.card {
	width: 450px;
	padding: 32px;
}
.card__default {
	height: 100%;
}
.card__small {
	height: 227px;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 12px;
	margin-bottom: 44px;
}

.title {
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	font-style: normal;
	color: #2e294e;
	margin: 0;
}

.description {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: #9d9dab;
	margin: 0;
}

.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 44px;
	gap: 12px;
}

.inactiveMessage {
	color: #9d9dab;
}

.validMessage {
	color: green;
}

.invalidMessage {
	color: red;
}

.messageContainer {
	padding-bottom: 2vh;
}

.link {
	align-self: flex-end;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: #0b615b;
}

.mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.element {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}
