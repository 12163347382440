.cancel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9px 17px;
	gap: 8px;
	max-width: 78px;
	height: 38px;
	background: #ffffff;
	border: 1px solid #2e294e;
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	color: #2e294e;
	margin-right: 18px;
}

.title {
	margin-left: 7px;
}

.cancel:hover {
	background: #ffffff;
	color: #2e294e;
}

.addGrid {
	text-align: center;
	padding: 9px 17px;
	min-width: 101px;
	gap: 8px;
	height: 38px;
	border: 1px solid #0b615b;
	background: #0b615b;
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	color: #ffffff;
	font-size: 14px;
}
.addGrid:hover {
	background: #ffffff;
	color: #0b615b;
	stroke: #084d45;
}

.buttonActionText {
	display: flex;
	align-items: center;
	font-weight: 700;
	gap: 8px;
	color: black;
}
.buttonActionText__downIcon {
	margin-left: 5px;
}

.buttonEditGrid {
	border: 1px solid #0b615b;
	border-radius: 8px;
	padding: 8px;
	background-color: white;
}

.editGridIcon {
	color: #ffffff;
	width: 24px;
}

.buttonDeleteGrid,
.buttonMore {
	max-height: 38px;
	border: 1px solid #0b615b;
	border-radius: 8px;
	padding: 8px;
	background-color: white;
}

.buttonMore {
	padding-block: 8px;
	padding-inline: 8px;
}

.buttonAction {
	background: #fff;
	border-radius: 4px;
	padding: 9px 17px;
}

.deleteGridIcon {
	width: 24px;
}

.submitButtonContainer {
	display: flex;
	justify-content: end;
	margin-top: 10px;
}

.root {
	background-color: #0b615b;
	color: #ffffff;
	padding: 9px 17px;
	height: 38px;
	width: 100%;
	border-radius: 4px;
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
}
.root:hover {
	background-color: #084d45;
	border: 1px solid #084d45;
}
.root:disabled {
	background-color: #9d9dab;
	border: 1px solid #9d9dab;
}

.wideButton {
	width: 100%;
}

.confirmationButton {
	width: 100%;
	padding: 9px 17px;
	height: 38px;
	border-radius: 4px;
}

.deleteConfirmationButton {
	background-color: #e50606;
	color: #ffffff;
	width: 100%;
	padding: 9px 17px;
	border: 1px solid transparent;
	height: 38px;
	border-radius: 4px;
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
}
.deleteConfirmationButton:hover {
	background-color: #fff;
	color: #e50606;
	stroke: #e50606;
	border: 1px solid #e50606;
}
.deleteConfirmationButton:disabled {
	background-color: #9d9dab;
	border: 1px solid #9d9dab;
}

.viewButton {
	background-color: white;
	color: black;
}
.viewButton__title {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}
.viewButton__label {
	font-size: 14px;
	font-weight: 700;
}
.viewButton:hover {
	background-color: #f2f2f2;
	border: 1px solid #f2f2f2;
}
.viewButton:disabled {
	background-color: #f2f2f2;
	border: 1px solid #9d9dab;
}

.iconButton {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	min-width: 110px;
	height: 38px;
	border: 1px solid #0b615b;
	background: #0b615b;
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	color: #ffffff;
}
.iconButton__label {
	font-size: 14px;
	font-weight: 550;
	line-height: 16px;
}

.whiteButton {
	text-align: center;
	padding: 9px 17px;
	gap: 8px;
	width: 101px;
	height: 38px;
	box-shadow:
		0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	background: #fff;
	color: black;
	font-size: 14px;
	font-weight: 700;
}
