.cardsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
}

.horizontalLine {
	margin-top: -10px;
	border: 1px solid #f2f2f2;
	height: 0px;
}

.descriptionContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.label {
	font-size: 12px;
	color: #9d9dab;
}

.value {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #08070d;
}

.tagValueContainer {
	display: flex;
	font-weight: 400;
	font-size: 12px;
	color: #08070d;
}

.tag {
	margin-left: 3px;
}

.buttonsContainer {
	display: flex;
	justify-content: right;
	margin-top: 16px;
	gap: 8px;
}
