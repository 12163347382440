.openCloseIconContainer {
	margin: 8px 16px;
}
.openCloseIconContainer:focus {
	border: none;
	border-color: none;
	box-shadow: none;
}

.openCloseIcon {
	padding-block: 16px;
	margin-left: auto;
	margin-right: auto;
}
