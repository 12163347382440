.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nameWrapper {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	gap: 8px;
}

.link {
	text-decoration: none;
	color: #0563c1;
	padding: 3px 8px;
	border-radius: 2px;
	background: #dadae0;
}

.title {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #08070d;
	padding: 0;
	margin: 0;
}

.actions {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-left: 24px;
}

.revenue {
	display: flex;
	align-items: center;
	background: #9d9dab;
	border-radius: 20px;
	padding: 4px 8px;
	height: 22px;
}
.revenue__value {
	color: #ffffff;
	font-size: 12px;
	line-height: 14px;
	font-weight: 700;
}
