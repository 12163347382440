.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.transferred {
	width: 100px;
	color: #6d6d7d;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	padding: 8px;
	margin-left: 24px;
	border-radius: 8px;
	background: #f2f2f2;
}

.section {
	display: flex;
	align-items: center;
}

.information {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	margin-right: 24px;
}

.title {
	display: flex;
	align-items: center;
	gap: 8px;
}

.type {
	color: #0b615b;
	font-size: 18px;
	line-height: 21px;
	font-weight: 700;
}

.cost {
	display: flex;
	align-items: center;
	background: #9d9dab;
	border-radius: 20px;
	padding: 4px 8px;
	height: 22px;
}
.cost__value {
	color: #ffffff;
	font-size: 12px;
	line-height: 14px;
	font-weight: 700;
}

.location {
	font-size: 12px;
	line-height: 14px;
	color: #9d9dab;
}

.goods {
	display: flex;
	align-items: center;
	gap: 16px;
	transition-duration: 0.3s;
}
.goods__more {
	color: #2e294e;
}

.actions {
	display: flex;
	gap: 16px;
	margin-left: 24px;
	align-items: center;
}
