.card {
	background-color: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.08);
}

.customCard__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.customCard__header__title {
	color: #0b615b;
	font-size: 0.9rem;
	font-weight: 700;
}
.customCard__header__buttons {
	display: flex;
}

.addressCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 130px;
	width: 100%;
	padding: 8px;
	gap: 16px;
	background: #f2f2f2;
	box-shadow: 4px 4px 2px rgba(20, 53, 91, 0.12);
	border: 1px solid #ffffff;
	border-radius: 8px;
}
.addressCard__header {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
}
.addressCard__header__title {
	font-weight: 400;
	font-size: 0.65rem;
	line-height: 14px;
	color: #9d9dab;
}
.addressCard__content {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.addressCard__content__name {
	color: #0b615b;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@supports (-webkit-line-clamp: 2) {
	.addressCard__content__name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
.addressCard__content__address {
	color: #000000;
	font-weight: 700;
	font-size: 0.7rem;
	line-height: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@supports (-webkit-line-clamp: 2) {
	.addressCard__content__address {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
.addressCard__content__date {
	color: black;
	font-size: 0.65rem;
	font-weight: 400;
}
@media only screen and (min-width: 1920px) {
	.addressCard {
		padding: 16px;
		min-height: 150px;
	}
}

.detailsCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: #f2f2f2;
	box-shadow: 4px 4px 2px rgba(20, 53, 91, 0.12);
	border: 1px solid #ffffff;
	border-radius: 8px;
	min-height: 72px;
	padding: 8px;
	gap: 16px;
}
.detailsCard__header {
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
}
.detailsCard__header__title {
	color: #9d9dab;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
}
@media only screen and (min-width: 1920px) {
	.detailsCard__header__title {
		font-size: 14px;
	}
}
.detailsCard__content__value {
	color: #08070d;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
}
